<template>
  <v-card class="pa-6" elevation="0">
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          style="position: absolute; top: 5px; right: 5px"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="toggleActiveState" :disabled="disableActions">
          <v-list-item-title>{{
            user.active ? "Disable" : "Enable"
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="showDeleteDialog = true"
          :disabled="disableActions"
        >
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <div class="d-flex flex-column justify-center align-center">
      <v-avatar class="ml-2" size="100">
        <img
          :src="
            user.image_url ||
            'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'
          "
          alt="avatar"
        />
      </v-avatar>
      <span class="email"> {{ user.primary_email }} </span>
      <span class="date"> {{ formattedDateCreated }} </span>
      <v-chip class="ma-3" x-small :color="chipColor" text-color="white">
        {{ user.active ? "ACTIVE" : "INACTIVE" }}
      </v-chip>
    </div>
    <v-dialog v-model="showDeleteDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Delete User </v-card-title>
        <v-card-text
          >Are you sure you want to delete this user from the
          organization?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="deleteUserFromOrganization()"
          >
            Delete
          </v-btn>
          <v-btn color="green darken-1" text @click="showDeleteDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import { format } from "date-fns";
import firebase from "firebase/app";
import "firebase/firestore";
export default {
  data() {
    return {
      showDeleteDialog: false,
    };
  },
  props: {
    user: {
      type: Object,
      require: true,
    },
  },

  computed: {
    ...mapState({
      currentUser: (state) => state.auth.currentUser,
    }),
    chipColor() {
      return this.user.active ? "#4ce051" : "#e04c4c";
    },
    formattedDateCreated() {
      return format(this.user.date_joined.toDate(), "do MMM; yyyy");
    },
    disableActions() {
      return this.currentUser.uuid === this.user.uuid;
    },
  },

  methods: {
    async toggleActiveState() {
      try {
        await firebase
          .firestore()
          .collection("users")
          .doc(this.user.uuid)
          .update({
            active: !this.user.active,
          });
      } catch (error) {
        this.$toast.error("Failed toggle state");
      }
    },

    async deleteUserFromOrganization() {
      try {
        this.showDeleteDialog = false;
        await firebase
          .firestore()
          .collection("users")
          .doc(this.user.uuid)
          .update({
            org_id: null,
          });
        this.$toast.success("User deleted");
      } catch (error) {
        this.$toast.error("Failed toggle state");
      }
    },
  },
};
</script>
<style scoped>
.email {
  font-weight: bold;
  font-size: 0.9rem !important;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.date {
  margin-top: 5px;
  font-weight: 300;
  color: #c1c1c1;
  font-size: 1rem;
}
</style>
