<template>
  <div style="display: inline-block">
    <v-btn text color="primary" @click="onUserAddClicked">
      {{ isBulkUpload ? "Add Bulk User" : "Add User" }}
      {{ loading ? "..." : "" }}
    </v-btn>
    <v-dialog v-model="showAddDialog" max-width="290">
      <v-card>
        <validation-observer ref="userAddObserver">
          <v-card-title class="text-h5"> Add User </v-card-title>
          <v-card-text>
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <v-text-field
                label="Email*"
                required
                v-model="email"
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="addSingleEmail()">
              Add {{ loading ? "..." : "" }}
            </v-btn>
            <v-btn color="green darken-1" text @click="showAddDialog = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>
    <input type="file" ref="csvPicker" @change="readFile()" hidden />
  </div>
</template>
<script>
import { mapState } from "vuex";
import firebase from "firebase/app";
import "firebase/firestore";

export default {
  props: {
    isBulkUpload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      showAddDialog: false,
      email: "",
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.auth.currentUser,
      currentOrganization: (state) => state.auth.currentOrganization,
    }),
  },

  methods: {
    async onUserAddClicked() {
      if (this.isBulkUpload) {
        this.$refs.csvPicker.click();
      } else {
        this.showAddDialog = true;
      }
    },
    async addSingleEmail() {
      const valid = await this.$refs.userAddObserver.validate();
      if (valid) {
        await this.addEmailsToOrganization([this.email], true);
      }
    },
    async readFile() {
      try {
        const csvContent = await this.readCsvFile();
        console.log(csvContent);
        const emails = csvContent.split(",").map((email) => email.trim());
        console.log(emails);
        await this.addEmailsToOrganization(emails);
      } catch (error) {
        console.log(error);
        console.log("Error");
      }
    },
    async readCsvFile() {
      return new Promise((resolve, reject) => {
        this.file = this.$refs.csvPicker.files[0];
        if (!this.file.name.includes(".csv")) {
          reject(new Error("Invalid file type"));
        } else {
          var reader = new FileReader();
          reader.onload = (event) => {
            const csvContent = event.target.result;
            console.log(csvContent);
            resolve(csvContent);
          };
          reader.onerror = () => reject(new Error("Failed to read file"));
          reader.readAsText(this.file);
        }
      });
    },
    async addEmailsToOrganization(emails, isSingle) {
      const userText = emails.length > 1 ? "users" : "user";
      try {
        this.loading = true;
        let batch = firebase.firestore().batch();

        for (let i = 0; i < emails.length; i++) {
          let organizationUserDoc = firebase
            .firestore()
            .collection("bulkupload")
            .doc(this.currentOrganization.id)
            .collection("users")
            .doc();
          let currentTimestamp =
            firebase.firestore.FieldValue.serverTimestamp();
          batch.set(organizationUserDoc, {
            created: false,
            date_uploaded: currentTimestamp,
            email: emails[0],
            password: "changeme",
            org_id: this.currentOrganization.id,
            country: this.currentOrganization.country,
          });
        }
        await batch.commit();
        this.$toast.success(`Successfully added ${userText}`);
        if (isSingle) {
          this.showAddDialog = false;
        }
      } catch (error) {
        this.$toast.error(`Failed to add ${userText}`);
      }
      this.loading = false;
    },
  },
};
</script>
