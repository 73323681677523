<template>
  <div class="mt-5">
    <v-row class="align-center top-note mb-3">
      Total Users: {{ users ? users.length : "..." }}
      <v-spacer />
      <user-add-button />
      <user-add-button :isBulkUpload="true" />
    </v-row>
    <load-handler
      :loading="loading"
      :error-message="errorMessage"
      :empty-message="emptyMessage"
    >
      <v-row>
        <v-col md="4" lg="3" v-for="user in users" :key="user.email">
          <UserCard :user="user" />
        </v-col>
      </v-row>
    </load-handler>
  </div>
</template>
<script>
import { mapState } from "vuex";
import firebase from "firebase/app";
import LoadHandler from "../components/LoadHandler.vue";
import UserCard from "../components/UserCard.vue";
import UserAddButton from "../components/UserAddButton.vue";
import "firebase/firestore";

export default {
  components: {
    UserCard,
    LoadHandler,
    UserAddButton,
  },
  data() {
    return {
      loading: false,
      errorMessage: "",
      emptyMessage: "",
      users: [],
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.auth.currentUser,
      currentOrganization: (state) => state.auth.currentOrganization,
    }),
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      try {
        this.loading = true;
        this.errorMessage = "";
        this.emptyMessage = "";

        console.log(this.currentOrganization);

        firebase
          .firestore()
          .collection("users")
          .where("organization_id", "==", this.currentOrganization.id)
          .onSnapshot((snapshot) => {
            if (snapshot.empty) {
              this.emptyMessage = "No User found";
            } else {
              const users = snapshot.docs.map((doc) => doc.data());
              this.users = users;
            }
            this.loading = false;
          });
      } catch (error) {
        this.loading = false;
        this.errorMessage = error.message
          ? error.message
          : "Error fetching users";
      }
    },
  },
};
</script>
